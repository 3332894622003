import React from 'react';

const CloseIcon = () => (
	<svg
		width="17"
		height="18"
		viewBox="0 0 17 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.02588 1L8.51293 9M8.51293 9L16 17M8.51293 9L16 1M8.51293 9L1.02588 17"
			stroke="white"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
);

export default CloseIcon;
