import styled from 'styled-components';

export const Frame = styled.div`
	width: 100%;
	height: auto;
	background: white;
	box-shadow: 0px 4px 5px 2px rgb(0 0 0 / 5%);
`;
export const Container = styled.div`
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 24px;
	border-top: 1px solid #eaeaea;
`;
export const Wrapper = styled.div`
	width: 100%;
	height: auto;
	background: white;
	margin: 0px auto;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 10px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.8);
		box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}
`;
export const Details = styled.div`
	width: 100%;
	height: auto;
	padding: 48px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-top: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
	@media (max-width: 768px) {
		padding: 32px;
	}
	h4,
	h5,
	p {
		text-align: left !important;
	}
	h4 {
		font-weight: 800;
	}
	h5 {
		font-weight: 700;
	}
	p {
		font-weight: 500;
	}
`;
export const Thumbnail = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	padding: 30px 0px;
`;
export const Label = styled.div`
	position: absolute;
	bottom: 0px;
	width: 100%;
	height: auto;
	padding: 16px 0px;
	text-align: center;
	font-weight: 800;
	&::before {
		display: block;
		position: absolute;
		inset: 0px;
		content: '';
		width: 100%;
		height: 100%;
		background: linear-gradient(
			101.24deg,
			rgba(0, 7, 20, 0.85) 27.61%,
			rgba(38, 44, 54, 0.85) 103.49%
		);
		z-index: 1;
	}
	span {
		position: relative;
		z-index: 2;
		color: #ffffff;
	}
`;
