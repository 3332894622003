import React, { useState } from 'react';
import Button from '../Button';
import Image from '../Image';
import Icon from '../Icon';
import Modal from '../Modal';
import Text from '../Typography';
import {
	Frame,
	Wrapper,
	Container,
	Details,
	Thumbnail,
	Label,
} from './styles/ProjectCard.styled';

const ProjectCard = props => {
	const [showModal, setShowModal] = useState(false);
	return (
		<>
			<Modal showModal={showModal} setShowModal={setShowModal}>
				<Wrapper>
					<Image
						src={props?.featuredImage?.image?.gatsbyImageData}
						alt={props?.featuredImage?.image?.alt}
					/>
					<Details>
						<Text Element="h4" text={props?.internalName} classes="mb-1" />
						<Text Element="h5" text={props?.techStack} classes="mb-2" />
						<Text Element="div" html={props?.description} classes="mb-4" />
						<Button
							Element="a"
							text="Visit Site"
							variant="primary"
							to={props?.siteUrl}
							target="_blank"
						/>
					</Details>
					{props?.images?.map(({ image }) => (
						<Thumbnail key={image?.alt}>
							<Image
								src={image.gatsbyImageData}
								alt={image.alt}
								classes="mb-3 tooltip"
							/>
							<Label>
								<span>{image.alt}</span>
							</Label>
						</Thumbnail>
					))}
				</Wrapper>
			</Modal>
			<Frame>
				<Image
					src={props?.featuredImage?.image?.gatsbyImageData}
					alt={props?.featuredImage?.image?.alt}
				/>
				<Container>
					<Button
						Element="button"
						variant="secondary"
						classes="mr-2"
						handleClick={() => setShowModal(true)}
					>
						<Icon type="fas" icon="info-circle" />
						Details
					</Button>
					{props?.githubRepo && (
						<Button
							Element="a"
							to={props?.githubRepo}
							variant="primary"
							classes="ml-2"
							target="_blank"
						>
							<Icon type="fab" icon="github" color="white" />
							Github
						</Button>
					)}
					{!props?.githubRepo && (
						<Button
							Element="a"
							to={props?.siteUrl}
							variant="primary"
							classes="ml-2"
							target="_blank"
						>
							<Icon type="fas" icon="globe" color="white" />
							Visit Site
						</Button>
					)}
				</Container>
			</Frame>
		</>
	);
};

export default ProjectCard;
