import React from 'react';
import Text from '../Typography';
import Card from '../Card';
import { Section, Wrapper, Grid } from './styles/index.styled';

const CardDeck = ({ data }) => (
	<Section id={data?.id} background={data?.background}>
		<Wrapper>
			<Text Element="h5" text={data?.headingKicker} classes="mb-2" />
			<Text Element="h2" text={data?.heading} />
			<Grid>
				{data?.cards?.map(card => (
					<Card key={card?.id} {...card} />
				))}
			</Grid>
		</Wrapper>
	</Section>
);

export default CardDeck;
