import styled from 'styled-components';

export const Container = styled.dialog`
	position: fixed;
	inset: 0px;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	z-index: 10000;
	background: transparent;
	opacity: 0;
	pointer-events: none;
	transition: all 300ms ease-in-out;
	&.modal-active {
		opacity: 1;
		pointer-events: all;
	}
`;
export const Backdrop = styled.button`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5) !important;
	&:hover {
		background: rgba(0, 0, 0, 0.5) !important;
	}
`;
export const Wrapper = styled.div`
	position: relative;
	width: 100%;
	max-width: 1170px;
	height: 100%;
	max-height: 800px;
	display: flex;
	flex-direction: column;
	padding: 30px;
	margin: auto;
	z-index: 10001;
`;
