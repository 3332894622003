import styled, { css } from 'styled-components';

export const Frame = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	padding: 50px 40px 30px;
	margin-bottom: 40px;
	border: 2px solid rgb(209, 213, 219);
	border-radius: 0.125rem;
	border-top-right-radius: 1rem;
	@media (max-width: 768px) {
		padding: 50px 30px 24px;
	}
`;
export const Container = styled.div`
	position: absolute;
	top: -40px;
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid black;
	background: var(--primary);
	border: 8px solid rgb(249, 250, 251);
`;
export const IconStyles = css`
	width: 50px;
	use {
		fill: white;
	}
`;
