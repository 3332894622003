import React from 'react';
import Text from '../Typography';
import Icon from '../Icon';
import { Frame, Container, IconStyles } from './styles/ServiceCard.styled';

const Card = ({ internalName, description, icon }) => (
	<Frame>
		<Container>
			<Icon type={icon?.iconType} icon={icon?.icon} styles={IconStyles} />
		</Container>
		<Text Element="h6" text={internalName} classes="mb-2" />
		<Text Element="div" html={description} classes="mb-2" />
	</Frame>
);

export default Card;
