import styled from 'styled-components';

export const Button = styled.button`
	width: 100%;
	height: auto;
	width: 14.97px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: auto;
	padding: 0px;
	font-size: 16px;
	margin-bottom: 24px;
	background: transparent;
	border: none;
	outline: none;
	opacity: 1;
	transition: opacity 400ms ease;
	&:hover {
		opacity: 0.5;
		background: none;
	}
`;
