import React from 'react';
import ServiceCard from './ServiceCard';
import ProjectCard from './ProjectCard';

const Card = props => {
	switch (props?.internal?.type) {
		case 'DatoCmsEntityService':
			return <ServiceCard {...props} />;
		case 'DatoCmsEntityProject':
			return <ProjectCard {...props} />;
		default:
			return null;
	}
};

export default Card;
