import styled, { css } from 'styled-components';
import AboutBackground from '../../../images/about_bg.webp';

const backgrounds = {
	neutral: css`
		background-color: rgb(249, 250, 251);
	`,
	graphic: css`
		background: transparent;
		&::before {
			content: '';
			position: absolute;
			inset: 0px;
			width: 100%;
			height: 100%;
			background-image: url(${AboutBackground});
			background-size: cover;
			background-repeat: no-repeat;
			transform: rotate(180deg);
			z-index: -1;
			@media (max-width: 992px) {
				background-image: none;
			}
		}
	`,
};

export const Section = styled.section`
	position: relative;
	width: 100%;
	height: auto;
	padding: 96px 0px;
	${props => backgrounds[props?.background]}
	@media (max-width: 992px) {
		background-image: none;
		padding: 72px 0px;
	}
`;
export const Wrapper = styled.div`
	width: 100%;
	max-width: 1598px;
	height: auto;
	padding: 0px 30px;
	margin: 0px auto;
	@media (max-width: 575px) {
		h5,
		h2 {
			text-align: center !important;
		}
	}
`;
export const Grid = styled.div`
	width: 100%;
	height: auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	margin-top: 64px;
	@media (max-width: 992px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
	}
`;
